* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

$logoColorDown: "./assets/img/logo.svg";
$logoColorPath: "./assets/img/logo-text.svg";
$logoColor: "./assets/img/logo-color.svg";
$hamburgerPath: "./assets/img/hamburger.svg";
$foreground-color: white;
$theme-color: #224082;
$primary-color: #212121;
$active-text: #0f6faa;

@font-face {
  font-family: "MyriadPro";
  src: url("MyriadPro-Regular.eot");
  src: url("MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("MyriadPro-Regular.woff2") format("woff2"),
    url("MyriadPro-Regular.woff") format("woff"), url("MyriadPro-Regular.ttf") format("truetype"),
    url("MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProBoldCondensed';
  src: url('Myriad-Pro-Bold-Condensed.eot');
  src: url('Myriad-Pro-Bold-Condensed.eot?#iefix') format('embedded-opentype'),
       url('Myriad-Pro-Bold-Condensed.woff2') format('woff2'),
       url('Myriad-Pro-Bold-Condensed.woff') format('woff'),
       url('Myriad-Pro-Bold-Condensed.ttf')  format('truetype'),
       url('Myriad-Pro-Bold-Condensed.svg#Myriad Pro') format('svg');
}

@font-face {
  font-family: 'MyriadProBold';
  src: url('Myriad-Pro-Bold.eot');
  src: url('Myriad-Pro-Bold.eot?#iefix') format('embedded-opentype'),
       url('Myriad-Pro-Bold.woff2') format('woff2'),
       url('Myriad-Pro-Bold.woff') format('woff'),
       url('Myriad-Pro-Bold.ttf')  format('truetype'),
       url('Myriad-Pro-Bold.svg#Myriad Pro') format('svg');
}

@import "minigame-template";
@import "video-template";
@import "dropzone";

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: "MyriadPro", sans-serif;
  // font-size: 0.8rem;
  // font-size: calc(0.75em + 1vmin);
  font-weight: 400;
  color: #212121;
  background: $foreground-color;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}
.body-overview {
  margin: 15px auto;
  max-width: 1200px;
}

.overflow-scroll {
  overflow: scroll;
}
.cus-rs-dropdown-menu {
  ul {
    margin-left: 5px;
  }
}

.mt-50px {
  margin-top: 50px;
}
.no-margin {
  margin: 0 !important;
}

.red {
  color: red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.error-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .item {
    width: 100vh;
    text-align: center;
  }
  img {
    height: 260px;
  }
}

/**
Login Form
*/
input.login-container__username::placeholder,
input.login-container__password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}
.login-container {
  margin: 0 auto;
  background-color: $theme-color;
  display: table;
  width: 100%;
  height: 100vh;

  .login-container__language {
    float: right;
  }

  .login-container__form {
    position: absolute;
    width: 350px;
    height: 480px;
    background-color: rgba(248, 247, 249, 0.2);
    top: 50%;
    left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    padding: 10px 40px;
    border-radius: 30px;
    transform: translate(-50%, -50%);

    .side-drawer-logo-container {
      text-align: center;
      margin: 30px;
      margin-top: 70px;

      .side-drawer-account-container {
        min-height: 100px;
        text-align: center;
        position: relative;

        .side-drawer-accounts__avatar {
          position: absolute;
          left: 0;
          top: 0;

          img {
            width: 72px !important;
            height: 72px !important;
            border-radius: 50%;
          }
        }
        .side-drawer-accounts__name {
          color: #fff;
          position: absolute;
          right: 0px !important;
        }
      }
    }

    .side-drawer-logo {
      width: 110px;
      height: 100px;
      background: url($logoColorDown) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
    }

    .login-container__input {
      font-size: 16px;
      color: #f9f9f9;

      .login-container__password {
        font-size: 18px;
        padding: 20px;
        margin-bottom: 15px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-password.svg") top left no-repeat;
        background-size: 23px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }
    }

    .login-container__btn-wraper {
      text-align: center;
      .login-container__btn {
        margin: 0 auto;
        background-color: rgba(78, 143, 255, 0.8);
        width: 100%;
        border: 1px solid rgba(78, 143, 255, 1);
        border-radius: 20px;
        font-size: 20px;
        color: #fff;

        &:hover {
          background-color: rgba(96, 154, 255, 0.8);
          color: #f9f9f9;
        }
      }
    }
  }
}
/** End Login **/

/** Toolbar **/
.toolbar {
  background-color: #fff;
  width: 100%;
  // height: 65px;
  // font-size: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 10;

  .toolbar__navigation {
    display: flex;
    height: 88px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;

    &--logo {
      width: 90px;
      height: 40px;
      background: url($logoColorPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
      margin: 0px 10px;
      cursor: pointer;
    }

    &--menu {
      flex: 0 1 auto;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      list-style: none;
      align-items: center;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;

      li {
        padding: 0;
        flex: 0 0 auto;
        display: flex;
        align-items: stretch;
      }

      li a {
        text-decoration: none;
        color: $primary-color;
        font-weight: 500;
        color: #11161d;
        padding-right: 25px;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 2;
        padding: 0 10px;

        &:hover {
          color: $active-text;
        }
      }

      li a.active {
        color: $active-text;
      }

      .as-btn {
        // padding: 12px 15px 8px 15px;
        // margin-left: 5px;
        // margin-bottom: 5px;
        // // font-family: "MyriadPro", sans-serif;

        // li {
        //   font-size: 1.4vw;
        // }

        color: #fff;
        font-size: 12px;
        border-radius: 100px;
        box-sizing: border-box;
        padding: 0.5em 1.24em;
        letter-spacing: 0.04em;
        transition: all 0.1s ease-in-out;
        line-height: 1;
        margin: auto;
        padding: 0.6em 1.24em;
        font-size: 13px;
        line-height: 1.4;

        &:hover {
          li {
            color: #7b27ab;
          }
        }

        ul li {
          padding: 10px 5px;
        }
      }
    }
  }
}
/** END Toolbar **/

/** Card**/
.card {
  width: auto;
  height: 370px;
  background-color: $foreground-color;
  margin-bottom: 24px;
  border-radius: 12px;
  transition: all 0.25s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  &--mini {
    min-width: 250px;
  }
  &--lg {
    height: 520px;
  }
  &--title {
    font-size: 16px;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #344b60;
  }
  &--title-right {
    position: absolute;
    right: 25px;
  }
  &--head-inline {
    display: inline-flex;
    align-items: center;

    p {
      margin-right: 25px;
    }
  }
  &--subtitle {
    margin: 10px 0px;

    & > * {
      margin: 0px 5px;
    }
  }
}

.card-super-mini {
  margin: 0 auto;
  height: unset;
  max-width: 480px;
  min-width: 150px;
  background-color: $foreground-color;
  margin-bottom: 24px;
  border-radius: 12px;
  transition: all 0.25s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  p {
    font-size: 40px;
    font-weight: bold;
  }

  q {
    font-size: 14px;
    font-weight: bold;
  }

  .rs-divider-with-text {
    margin: 18px 0px !important;
  }
}
/** End Card**/

@media (max-width: 767px) {
  .body-overview {
    margin: 5px 0;
  }
  .toolbar__navigation--menu {
    display: none !important;
  }

  .hamburger-icon {
    position: absolute;
    right: 0;

    .container {
      display: inline-block;
      cursor: pointer;
    }

    .logo {
      width: 34px;
      height: 30px;
      background: url($hamburgerPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
      margin: 0px 10px;
      cursor: pointer;
    }

    &--menu {
      // font-family: "UTMAvoBold", sans-serif;
      font-size: 1rem;
      font-weight: 600;

      li {
        padding: 10px 12px;
      }

      li a {
        text-decoration: none;
        color: $primary-color;

        &:hover {
          color: $active-text;
        }
      }

      li a.active {
        color: $active-text;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .toolbar {
    .toolbar__navigation {
      padding: 0 5vw !important;
    }
  }
}

@media (min-width: 768px) {
  .toolbar {
    .toolbar__navigation {
      padding: 0 10vw;
      height: 70px;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .accounts__container {
    margin: 0 auto;
  }

  .card {
    min-width: 370px;
  }
}