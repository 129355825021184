.minigame-template {
    position: relative;
    margin: 0px 5px;
    display: flex;
    padding: 20px;

    &__image {
        vertical-align: top;
        height: 180px;
        object-fit: cover;
        margin-right: 5px;
        width: 160px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: 900;
        color: #ccc;

        &:hover {
            cursor: pointer;
        }

        img {
            vertical-align: top;
            height: 180px;
            object-fit: cover;
            margin-right: 5px;
            width: 160px;
            justify-content: center;
            display: flex;
            align-items: center;
            font-weight: 900;
            color: #ccc;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        width: 100%;

        h6 {
            padding: 0px 0px 5px 0px;
        }
        &--description {
            flex-grow: 1;
            overflow-y: scroll;
            margin: 0px 0px 5px 0px;
        }

        input {
            margin: 5px 0px;
        }
    }
}

.minigame-container {
    position: relative;
    margin: 0px 5px;
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 5px 0px;
    height: 160px;

    &__image {
        vertical-align: top;
        height: 150px;
        object-fit: cover;
        margin-right: 5px;
        width: 120px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;

        h6 {
            padding: 0px 0px 5px 0px;
        }
        &--description {
            flex-grow: 1;
            overflow-y: scroll;
            margin: 0px 0px 5px 0px;
        }
    }
}

.m-tb-5 {
    margin: 5px 0px;
}
