$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-1: #224082;
$theme-color-2: #3498ff;
$theme-color-3: #3498ff8c;
$theme-color-4: #2296f3;
$theme-color-imgage-2: linear-gradient(90deg, #6384d3, #365293);
$foreground-color: white;
$primary-color: #212121;
$button-text-color: #fff;
$input-background: white;

/** Dropzone **/
.dropzone {
    min-height: 115px !important;
    border: 2px dotted $separator-color !important;
    background: $input-background !important;
    padding: 10px 10px !important;
    border-radius: $border-radius !important;
    color: $primary-color !important;
    height: auto !important;
  
    .img-thumbnail {
      height: 58px !important;
      width: 100% !important;
      object-fit: cover !important;
    }
  }
  
  .dropzone.dz-clickable .dz-message,
  .dropzone.dz-clickable .dz-message * {
    position: relative;
    transform: translateY(-50%);
    top: 24px !important;
    margin: 0;
  }
  
  .dropzone .dz-preview.dz-image-preview,
  .dropzone .dz-preview.dz-file-preview {
    width: 260px;
    height: 60px;
    min-height: unset;
    border: 1px solid $separator-color !important;
    border-radius: $border-radius !important;
    background: $foreground-color !important;
    color: $primary-color !important;
  
    .preview-container {
      transition: initial !important;
      animation: initial !important;
      margin-left: 0;
      margin-top: 0;
      position: relative;
      width: 100%;
      height: 100%;
  
      i {
        color: $theme-color-1;
        font-size: 20px;
        position: absolute;
        left: 50%;
        top: 29px;
        transform: translateX(-50%) translateY(-50%) !important;
        height: 22px;
      }
    }
  
    strong {
      font-weight: normal;
    }
  
    .remove {
      position: absolute;
      right: 5px;
      top: 5px;
      color: $theme-color-1 !important;
    }
  
    .dz-details {
      position: static;
      display: block;
      opacity: 1;
      text-align: left;
      min-width: unset;
      z-index: initial;
      color: $primary-color !important;
    }
  
    .dz-error-mark {
      color: $button-text-color !important;
      top: 15px;
      left: 25px;
      margin-left: 0;
      margin-top: 0;
  
      span {
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
            $theme-color-1
          ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
            $foreground-color
          ) + ";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:" + encodecolor(
            $foreground-color
          ) + ";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
        width: 28px;
        height: 28px;
      }
    }
  
    .dz-success-mark {
      color: $button-text-color;
      top: 15px;
      left: 25px;
      margin-left: 0;
      margin-top: 0;
  
      span {
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor(
            $theme-color-1
          ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor(
            $foreground-color
          ) + ";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
        width: 28px;
        height: 28px;
      }
    }
  
    .dz-progress {
      width: 84%;
      margin-left: 0;
      margin-top: 0;
      right: 0;
      height: 5px !important;
      left: 15px;
  
      .dz-upload {
        width: 100%;
        background: $theme-color-1 !important;
      }
    }
  
    .dz-error-message {
      border-radius: 15px;
      background: $error-color !important;
      top: 60px;
  
      &:after {
        border-bottom: 6px solid $error-color !important;
      }
    }
  
    [data-dz-name] {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
      display: inline-block;
      overflow: hidden;
    }
  }
  
  .dropzone .dz-preview.dz-file-preview .img-thumbnail {
    display: none;
  }
  
  .dropzone .dz-error.dz-preview.dz-file-preview {
    .preview-icon {
      display: none;
    }
  
    .dz-error-mark,
    .dz-success-mark {
      color: $theme-color-1 !important;
    }
  }
  
  .dropzone .dz-preview.dz-image-preview .preview-icon {
    display: none;
  }
  /** END Dropzone **/