.container-videos {
    margin: 5px;
}

.grid-video {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 5px;
    list-style-type: none;

    span {
        font-size: 14px;
    }
}

.title-video {
    border-bottom: 5px solid #2296f3;
    width: fit-content;
    margin-bottom: 5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.font-weight-600 {
    font-weight: 600;
}

.categories-list {
    margin-bottom: 5px;
}